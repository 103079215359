import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './App.css';
import { Tabs } from 'antd';
// import Item from 'antd/lib/list/Item';
import { List } from 'antd';
// import Column from 'antd/lib/table/Column';

const { TabPane } = Tabs;


function App() {
  useEffect(() => {
    getList()
  }, [])
  let [V2EX, setV2EX] = useState([])
  let [V2EXList, setListV2EX] = useState([])
  let [GitHub, setGitHub] = useState([])
  let [GitHubList, setListGitHub] = useState([])
  let [zhihu, setzhihu] = useState([])
  let [zhihuList, setListzhihu] = useState([])
  let [weibo, setweibo] = useState([])
  let [weiboList, setListweibo] = useState([])
  let [douban, setdouban] = useState([])
  let [doubanList, setListdouban] = useState([])
  let [tieba, settieba] = useState([])
  let [tiebaList, setListtieba] = useState([])
  let [music, setmusic] = useState([])
  let [musicList, setListmusic] = useState([])
  let [tianya, settianya] = useState([])
  let [tianyaList, setListtianya] = useState([])


  // let [obj, setList] = useState([])
  const getList = () => {
    axios({
      method: 'GET',
      url: 'https://gh.451024.xyz/https://raw.githubusercontent.com/toolslog/hot/master/result/result.json',
    }).then(
      res => {
        // setList(res.data)
        setV2EX(res.data[0].hot_name)
        setListV2EX(res.data[0].content)
        setGitHub(res.data[1].hot_name)
        setListGitHub(res.data[1].content)
        setzhihu(res.data[2].hot_name)
        setListzhihu(res.data[2].content)
        setweibo(res.data[3].hot_name)
        setListweibo(res.data[3].content)
        setdouban(res.data[4].hot_name)
        setListdouban(res.data[4].content)
        settieba(res.data[5].hot_name)
        setListtieba(res.data[5].content)
        setmusic(res.data[6].hot_name)
        setListmusic(res.data[6].content)
        settianya(res.data[7].hot_name)
        setListtianya(res.data[7].content)
        // console.log(res.data);
      }
    ).catch(e => {
      console.log(e);
    })
  }

  return (
    <>
      

      <div className="comm-box">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab={<h3>{V2EX}</h3>} key="1">
            {/* <Divider orientation="right">更新于：</Divider> */}
            <List
              bordered={false}
              dataSource={V2EXList}
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item >{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{GitHub}</h3>} key="2">
            <List
              bordered={false}
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              dataSource={GitHubList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>  {zhihu} </h3>} key="3" >
            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={zhihuList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{weibo}</h3>} key="4">
            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={weiboList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{douban}</h3>} key="5">

            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={doubanList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{tieba}</h3>} key="6">
            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={tiebaList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{music}</h3>} key="7">
            {/* {
            musicList.map((item, numberN) => (
              <a href={item.href}> <p key={numberN} >{item.title}</p></a>
            ))
          } */}
            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={musicList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
          <TabPane tab={<h3>{tianya}</h3>} key="8">
            <List
              pagination={
                {
                  onChange: page => {
                    // console.log(page);
                  },
                  responsive: true,
                  // pageSize: 15,              
                }}
              bordered={false}
              dataSource={tianyaList}
              renderItem={item => <a className="liy" target="_blank" rel="noopener noreferrer" href={item.href}><List.Item>{item.title}</List.Item></a>}
            />
          </TabPane>
        </Tabs>
      </div>

    </>
  );
}

export default App;
